@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500,600,700');

@import "../../libs/bootstrap/bootstrap";

html, body {
	min-height: 100%;
}

main {
	font-family: 'Oswald';
	color: #2F1E1A;
	
	form {
		background-image: url('../images/BrownBag-bg.png');
		background-position: center center;
		background-repeat: no-repeat;
		padding: 6.5%;
		width: 600px;
		max-width: 90%;
		margin: 5% 0;
		
		img.logo {
			width: 65%;
			display: block;
			margin: 0 0 30px;
		}
		
		h3 {
			font-weight: 300;
			margin-bottom: 30px;
		}
	}
}